<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header flex-wrap">
        <h1>Payroll</h1>
        <span class="flex align-center flex-wrap">
          <router-link :to="{name: 'payrollQueue'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPayrollQueue, 'btn__outlined': !isPayrollQueue }">Payroll Queue</button>
          </router-link>
          <!-- <router-link :to="{name: 'paidAssignments'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPaidAssignments, 'btn__outlined': !isPaidAssignments }">Paid Assignments</button>
          </router-link> -->
          <router-link :to="{name: 'payables'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPayables, 'btn__outlined': !isPayables }">Employee Payables</button>
          </router-link>
          <router-link :to="{name: 'payrollWorkers'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isPayrollWorkers, 'btn__outlined': !isPayrollWorkers }">Active Workers</button>
          </router-link>
          
          <button class="btn btn__outlined btn__small" @click="goBack()"><i class="fas fa-arrow-left"></i></button>
        </span>
        
      </div>
      <router-view :key="$route.params.id" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'payrollHome',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    isPayrollQueue() {
      return this.$route.name == 'payrollQueue';
    },
    // isPaidAssignments() {
    //   return this.$route.name == 'paidAssignments';
    // },
    isPayables() {
      return this.$route.name == 'payables';
    },
    isPayrollWorkers() {
      return this.$route.name == 'payrollWorkers';
    },
  },
  methods: {
    goBack() {
      router.go(-1)
    },
  },
  beforeDestroy () {
    console.log(this)
  }
}
</script>